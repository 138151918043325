import { render, staticRenderFns } from "./manageChatbot.vue?vue&type=template&id=1ec83ea6&scoped=true"
import script from "./manageChatbot.vue?vue&type=script&lang=js"
export * from "./manageChatbot.vue?vue&type=script&lang=js"
import style0 from "./manageChatbot.vue?vue&type=style&index=0&id=1ec83ea6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ec83ea6",
  null
  
)

export default component.exports