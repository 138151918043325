<template>
    <div id="page-managemenu" class="page-managemenu">
        <b-row class="managemenurow">
            <b-col class="managemenucol">
                <b-card class="cardpresentation">
                    <div class="cardheader">
                        <span class="cardnumber rounded-circle bg-green">01</span>
                        <h5 class="cardtitle">Apresentação</h5>   
                    </div>
                    <!-- <div class="cardsubheader border-bottom-1 d-flex text-center align-items-center flex-column p-4">
                        <div>A apresentação ocorre quando um usuário envia uma mensagem ao canal.</div>
                        <div>Aqui você pode escolher uma imagem, texto ou ambos, para a apresentação do canal.</div>
                    </div> -->
                   
                    <div class="addimage" v-if="menu.linearImageEnabled">
                        <b-form-file id="menuImgUpload" style="display:none" accept=".jpg,.jpeg,.png" @input="setMenuImage" />
                        <label for='menuImgUpload' class="w-100">
                            <div class="cardpresentationimg" v-if="!menu.linearMedia?.url">
                                <div class="d-flex flex-column align-items-center">
                                    <b-icon class="cardpresentationicon" icon="image" />
                                    <div class="cardpresentationtext">Adicionar Imagem</div>
                                </div>
                            </div>
                            <div style="max-height:325px;" role="button" v-else>
                                <b-img style="max-height: 325px; width: 100%" :src="menu.linearMedia?.url" />
                            </div>
                        </label>
                    </div>
                    <div class="cardpresentationcontent">
                        <div class="cardpresentationcontenttop">
                            <b-form-checkbox class="cardpresentationcheckbox" v-model="menu.linearImageEnabled">Imagem</b-form-checkbox>
                            <!-- <b-button class="managemenubtngreen" v-b-modal.modal-carimbo>Inserir Carimbo</b-button> -->
                        </div>
                        <div class="textareaemoji-container" v-if="menu.linearTextEnabled">
                            <b-form-textarea class="px-4" v-model="menu.linearText" placeholder="Escreva seu texto" rows="5" max-rows="5" no-resize></b-form-textarea>
                            <b-button class="toggle-emoji darkenTextOnHover" @click="toggleDialogEmojiTextarea"><b-icon icon="emoji-smile"></b-icon></b-button>
                            <VEmojiPicker @select="selectEmojiTextarea" lang="pt-BR" v-show="showDialogTextarea" :i18n="i18n" />
                        </div>
                        <div class="cardpresentationcontentbottom">
                            <b-form-checkbox class="cardpresentationcheckbox" v-model="menu.linearTextEnabled">Texto</b-form-checkbox>
                            <div>
                                <!-- <b-button class="managemenubtngreen" v-b-modal.modal-evasivas>Evasivas</b-button> -->
                                <b-button class="managemenubtnpink" v-b-modal.modal-tags>Tags</b-button>
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col class="managemenucol">
                <b-card class="cardmainmenu">
                    <div class="cardheader">
                        <span class="cardnumber rounded-circle bg-green">02</span>
                        <h5 class="cardtitle">Menu Linear</h5>   
                    </div>
                    <div class="cardmainmenucontent vstack gap-3">
                        <div>
                            <b-button class="managemenubtngreen text-center w-100" @click="addMenu">Adicionar Novo Conteúdo</b-button>
                        </div>
                        <draggable tag="ul" :list="menu.content" class="list-group vstack" handle=".handle" @end="checkMove">
                            <li
                            class="vstack gap-3 py-3 border-bottom"
                            v-for="(item, index) in menu.content"
                            :key="item.option"
                            >
                                <div class="hstack gap-2">
                                    <div class="rounded border-grey2 text-secondary py-2 px-3">
                                        {{ index + 1 }}
                                    </div>
                                    <div class="w-100">
                                        <b-form-group>
                                            <b-form-select class="w-100 py-2 rounded border-grey text-secondary" v-model="item.type" @change="item.mediaURL = null">
                                                <b-form-select-option value="text">
                                                    Texto
                                                </b-form-select-option>
                                                <b-form-select-option value="image">
                                                    Imagem
                                                </b-form-select-option>
                                                <b-form-select-option value="file">
                                                    Arquivo
                                                </b-form-select-option>
                                                <b-form-select-option value="video">
                                                    Vídeo
                                                </b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                        <!-- <VEmojiPicker class="iconemojipicker" @select="selectEmojiIcon" lang="pt-BR" v-show="showDialogIcon" :i18n="i18n" v-if="itemSelected" /> -->
                                    </div>
                                    <b-button class="handle bg-grey border-0 py-2">
                                        <b-icon font-scale="1" icon="arrows-move" class="text-secondary"></b-icon>
                                    </b-button>
                                    <b-button class="cardmainmenuremovebtn border-0 py-2" v-b-tooltip.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modal-removeContent @click.prevent="selectItemToDelete(item)">
                                        <b-icon font-scale="1" icon="trash"></b-icon>
                                    </b-button>
                                </div>
                                <div>
                                    <div class="position-relative" v-if="item.type == 'text'">
                                        <b-form-textarea :ref="`textarea${index}`" class="px-4 border-grey2" v-model="item.text" placeholder="Escreva seu texto" rows="5" max-rows="5" no-resize></b-form-textarea>
                                        <b-icon class="position-absolute end-0 bottom-0 translate-middle text-secondary darkenTextOnHover" @click="toggleDialogEmoji(`textarea${index}`)" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                        <VEmojiPicker @select="selectEmoji($event, `textarea${index}`, item)" lang="pt-BR" v-show="showEmoji[`textarea${index}`]" :i18n="i18n" />
                                    </div>
                                    <div v-else>
                                        <b-form-file :id="`contentMediaUpload${index}`" style="display:none" 
                                            :accept="fileAccept(item.type)" 
                                            @input="setContentMedia($event, item)"
                                        />
                                        <label :for="`contentMediaUpload${index}`" class="w-100">
                                            <div class="bg-purple text-light p-5 rounded" role="button" v-if="!item.mediaURL">
                                                <div class="vstack align-items-center">
                                                    <b-icon font-scale="4" icon="image" />
                                                    <div class="fs-6">Adicionar Mídia</div>
                                                </div>
                                            </div>
                                            <div style="max-height:325px;" role="button" v-else>
                                                <b-img class="rounded" style="max-height: 325px; width: 100%" :src="item.mediaURL" v-if="item.type == 'image'" />
                                                <video class="w-100 rounded" style="max-height: 300px" :src="item.mediaURL" v-else-if="item.type == 'video'"></video>
                                                <div class="bg-dark text-light rounded vstack gap-1 align-items-center w-100 p-5" style="max-height: 300px" v-else-if="item.type == 'audio'">
                                                    <b-icon font-scale="4" icon="file-earmark-music" />
                                                    {{ item.mediaFilename }}
                                                </div>
                                                <div class="bg-dark text-light rounded vstack gap-1 align-items-center w-100 p-5" v-else-if="item.type == 'file'">
                                                    <b-icon
                                                        :icon="(item.mediaExt == 'csv' || (item.mediaExt == 'xlsx' || item.mediaExt == 'txt')) ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                                        :class="{ 'text-success': (item.mediaExt == 'csv' || item.mediaExt == 'xlsx'), 'text-danger': item.mediaExt == 'pdf'}" 
                                                        font-scale="4"
                                                    />
                                                    <span class="fs-6">
                                                        {{ item.mediaFilename }}
                                                    </span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="hstack">
                                    <b-form-group class="w-100" label="Temporizador:" label-class="text-secondary">
                                        <b-input-group class="hstack w-100">
                                            <b-form-input class="border-grey" v-model="item.timer" type="number" />
                                            <template #append>
                                                <div class="bg-grey text-secondary h-100 d-flex align-items-center rounded-end p-2 border">
                                                    Segundos
                                                </div>
                                            </template>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                            </li>
                        </draggable>
                        <div>
                            <b-form-group label="Ação a ser Efetuada Após Menu Linear:" label-class="text-secondary">
                                <b-form-select class="border-grey w-100 p-2 rounded" v-model="menu.linearAction">
                                    <b-form-select-option value="">Finalizar Atendimento</b-form-select-option>
                                    <b-form-select-option value="redirectDepartment">Encaminhar Atendimento para Departamento</b-form-select-option>
                                    <b-form-select-option value="redirectOperator">Encaminhar Atendimento para Operador</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group class="mt-2" label-class="text-secondary" :label="`Selecione o ${ menu.linearAction == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="['redirectDepartment','redirectOperator'].includes(menu.linearAction)">
                                <b-form-select class="w-100 rounded" v-model="menu.linearRedirectId" v-if="menu.linearAction == 'redirectDepartment'">
                                    <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                </b-form-select>
                                <b-form-select class="w-100 rounded" v-model="menu.linearRedirectId" v-else-if="menu.linearAction == 'redirectOperator'">
                                    <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                </b-card>
                <b-card class="cardbtns">
                        <b-button class="managemenubtngreen" @click="saveMenu(true)">Salvar</b-button>
                        <b-button class="managemenubtnwhite" @click="goToMain">Cancelar</b-button>
                </b-card>
            </b-col>
        </b-row>
        <b-modal id="modal-carimbo" title="Carimbo">
            <b-row class="modalinputrow">
                <b-col>
                    <b-form-group label="Espaçamento Horizontal (X):">
                        <b-form-input />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Espaçamento Vertical (Y):">
                        <b-form-input />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="modalinputrow">
                <b-col>
                    <b-form-group label="Fonte:">
                        <b-form-select />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-row>
                        <b-col>
                            <b-form-group label="Tamanho:">
                                <b-form-select />
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="Cor:">
                                <b-form-input class="forminput" value="hsl(43deg 89% 38%)" type="color" list="presetColors" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div>
                <b-button class="managemenubtngreen">Salvar</b-button>
                <b-button class="managemenubtnwhite" @click="$bvModal.hide('modal-carimbo')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-evasivas" class="managemenu-modal" title="Evasivas">
            <b>O que são evasivas?</b>
            <p>São aquelas palavras não identificadas pelo canal que o contato informa! A lista abaixo possui algumas palavras utilizadas pelo contato para tentar acessar os itens deste menu!</p>
            <div class="evasivas" v-for="item in evasivas" :key="item.id">{{item.name}}</div>
        </b-modal>
        <b-modal id="modal-tags" class="managemenu-modal" title="Tags" header-class="py-0 border-0" body-class="p-0">
            <div class="border-top text-secondary p-3" v-for="item in tags" :key="item.id">
                <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
            </div>
        </b-modal>
        <b-modal id="modal-removeContent" ref="modal-removeContent" title="Remover Menu" header-class="py-0" hide-footer v-if="itemToDelete">
            <div class="text-secondary">Tem certeza de que seja remover este menu?</div>
            <div class="mt-2">
                <b-button class="managemenubtnred" @click="deleteEl(itemToDelete)">Remover</b-button>
                <b-button class="ms-2 managemenubtnwhite" @click="$bvModal.hide('modal-removeContent')">Cancelar</b-button>
            </div>
        </b-modal>
        <datalist id="presetColors">
            <option>#b8860b</option>
            <option>#018b8c</option>
            <option>#ff4500</option>
            <option>#ce5c5c</option>
            <option>#a0522c</option>
            <option>#ab5aa7</option>
            <option>#87cefa</option>
            <option>#ff69b3</option>
            <option>#ffd701</option>
            <option>#32cd33</option>
            <option>#ff8c00</option>
            <option>#d63031</option>
            <option>#a29bfe</option>
            <option>#dfe6e9</option>
            <option>#000000</option>
        </datalist>
    </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import draggable from 'vuedraggable'
import api from '../services/apiService.js'
import { getToken } from '../services/userService.js'

export default {
    components: {
        VEmojiPicker,draggable
    },
    props: ['user'],
    mounted: function () {
        this.onCloseModal()
        this.getMenus()
        this.getRedirectData()
    },
    computed: {
        fileAccept() {
            return function (type) {
                switch(type) {
                    case 'image':
                        return 'image/*'
                    case 'audio':
                        return 'audio/*'
                    case 'video':
                        return '.mp4'
                    case 'file':
                        return 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow'
                }
            }
        }
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide',(bvEvent, modalId) => {
                if(modalId != 'modal-removeContent') {
                    this.itemSelected = null;
                }
            })
        },
        async getMenus() {
            const resp = await api.getMenus(this.user.channelId || this.user.roleId)
            // console.log('resp',resp)
            if(resp.statusCode === 200) {
                if(resp.menus.length) {
                    const mainMenuIndex = resp.menus.findIndex(el => !el.topMenuId)

                    if(!resp.menus[mainMenuIndex].content)
                        resp.menus[mainMenuIndex].content = []

                    this.menu = resp.menus[mainMenuIndex] // main menu
                    this.originalMenu = Object.assign({}, resp.menus.at(mainMenuIndex))
                } else {
                    const respCreate = await api.createMenu({ 
                        name: `Menu Principal ${this.user.channelId || this.user.roleId}`,
                        topMenuId: undefined,
                        refObjectMenus: undefined,
                        channelId: this.user.channelId || this.user.roleId,
                    })
                    // console.log('resp createMenu',resp)
                    if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu) {
                        this.$emit('msg', {
                            text: "Ocorreu um erro ao criar menu!",
                            success: false
                        })
                    } else {
                        this.menu = respCreate.menu
                    }
                }
            }
        },
        selectItem(item,topMenu,$event=null) {
            if(this.itemSelected) {
                this.saveMenu()
            }
            this.itemSelected = item;
            if(topMenu._id) {
                this.itemSelected.topId = topMenu._id
            }
            if($event) {
                this.toggleDialogEmojiIcon();
            }
        },
        selectItemToDelete(item) {
            this.itemToDelete = item;
        },
        toggleDialogEmojiTextarea() {
            this.showDialogTextarea = !this.showDialogTextarea;
        },
        toggleDialogEmojiIcon() {
            this.showDialogIcon = !this.showDialogIcon;
        },
        selectEmojiTextarea(emoji) {
            this.menu.linearText += emoji.data
            this.toggleDialogEmojiTextarea()
        },
        selectEmojiIcon(emoji) {
            this.itemSelected.icone = emoji.data
            this.toggleDialogEmojiIcon()
            this.itemSelected = null
        },
        checkMove: function() {
            this.menu.content.forEach((element,i) => element.option = `${i+1}`);
        },
        async addMenu() {
            this.menu.content.push({
                text: 'Conteúdo',
                type: 'text',
                mediaURL: '',
                mediaCaption: '',
                timer: 60
            })
        },
        deleteEl(element) {
            const menu = this.menu
            menu.content = menu.content.filter(function(el) { return el != element; });
            if(this.$refs['modal-removeContent']) {
                this.$refs['modal-removeContent'].hide()
            }
            this.itemToDelete = undefined
        },
        closeDelete() {
            this.remove = !this.remove
        },
        fileExt(filename) {
            if(filename && filename.split('').reverse().join('')) {
                return filename.split('').reverse().join('').split('.')?.at(0)?.split('').reverse().join('')
            }
            return ''
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode !== 200) {
                this.operators = []
                return
            }
            this.operators = resp.operators
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode !== 200) {
                this.departments = []
                return
            }
            this.departments = resp.departments
        },
        async getRedirectData() {
            this.getOperators()
            this.getDepartments()
        },
        async setMenuImage(e) {
            console.log(e)
            const typefile = e['type'].split('/')[0]

            if (this.user.channel.apiType === 'cloud' && typefile === 'image' && e.size > 5242800) {
                return this.$emit('msg', {
                    text: "O tamanho máximo permitido para imagens é de 5 MB",
                    danger: true
                })
            }

            if (e.size > 10000000) {
                this.$emit('msg', {
                    text: "O tamanho máximo permitido para envios é de 10 MB",
                    danger: true
                })
            }
            const formData = new FormData();
            e.filename = e.name
            formData.append('file',e)
            const token = getToken()
            const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber': this.user.channelId }
            const uploadResp = await api.upload(formData,headers)
            if(uploadResp.statusCode != 200) {
                return this.$emit('msg', {
                    text: "Ocorreu um erro! Tente novamente mais tarde!",
                    success: false,
                    countdown: 8
                })
            }
            const url = uploadResp.file.uploadDir
            this.$set(this.menu,"linearMedia", {
                url,
                type: typefile,
                filename: e.filename,
                ext: e.name.split('').reverse().join('').split('.')?.at(0)?.split('').reverse().join('')
            })
        },
        async setContentMedia(e, content) {
            console.log(e)
            if (e.size > 10000000) {
                this.$emit('msg', {
                    text: "O tamanho máximo permitido para envios é de 10 MB",
                    danger: true
                })
            }
            const formData = new FormData();
            e.filename = e.name
            formData.append('file',e)
            const typefile = e['type'].split('/')?.at(0)
            const token = getToken()
            const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber': this.user.channelId }
            const uploadResp = await api.upload(formData,headers)
            if(uploadResp.statusCode != 200) {
                return this.$emit('msg', {
                    text: "Ocorreu um erro! Tente novamente mais tarde!",
                    success: false,
                    countdown: 8
                })
            }
            const url = uploadResp.file.uploadDir
            content.mediaFilename = e.filename
            content.mediaExt = e.name.split('').reverse().join('').split('.')?.at(0)?.split('').reverse().join('')
            this.$set(content, "mediaURL", url)
        },
        async saveMenu() {
            if(!this.menu.content.length) {
                let msg = {
                    text: "É Preciso adicionar conteúdo!",
                    success: false
                }
                return this.$emit('msg',msg)
            }
            // if(this.menu.interactive)
            //     delete this.menu.interactive
            if(this.menu.interactive?.type)
                this.menu.interactive.type = ""
            if(!this.menu.topMenuId)
                this.menu.topMenuId = undefined
            if(!this.menu.refObjectMenus)
                this.menu.refObjectMenus = undefined

            if(this.menu.contentOrder?.length && this.menu.contentOrder.findIndex(el => el != 'text')) {
                let textIndex = this.menu.contentOrder.findIndex(el => el == 'text')
                if(textIndex >= 0) {
                    this.menu.contentOrder.splice(textIndex,1)
                }
            }

            if(!this.menu.isLinear)
                this.menu.isLinear = true

            if (this.menu.ai.enabled) {
                this.menu.ai = {
                    enabled: false,
                    presentation: false
                }
            }

            const menu = this.menu
            // console.log('save menu',menu)
            let resp = null
            if(!menu._id) {
                menu.name = "Menu Linear"
                menu.channelId = this.user.channelId || this.user.roleId
                resp = await api.createMenu(menu)
            } else {
                resp = await api.updateMenu({ menus: [ menu ] })
            }
            console.log('resp save',resp)

            if(resp.statusCode == 200 || resp.statusCode == 201) {
                this.$emit('msg', {
                    text: "Menu salvo com sucesso!",
                    success: true
                })
            } else {
                this.$emit('msg', {
                    text: "Ocorreu um erro ao salvar menu!",
                    success: false
                })
            }
        },
        selectEmoji(emoji, textareaRef, item) {
            const textarea = this.$refs[textareaRef]?.at(0)

            if(textarea) {
                const sentence = textarea.vModelValue
                const len = sentence.length
                let pos = textarea.selectionStart
                if (pos === undefined) {
                    pos = 0
                }
    
                const before = sentence.substr(0, pos)
                const after = sentence.substr(pos, len)


                item.text = before + emoji.data + after

                this.toggleDialogEmoji(textareaRef)
            }
        },
        toggleDialogEmoji(textareaRef) {
            this.$set(this.showEmoji,textareaRef,!this.showEmoji[textareaRef])
        },
        async goToMain() {
            // if(this.originalMenu && this.menu != this.originalMenu) {
            //     await api.updateMenu({ menus: [ this.originalMenu ] })
            // }
            this.$router.replace({path:'/'})
        },
    },
    data() {
        return{
            remove:null,
            sendInputMedia: [],
            itemSelected: null,
            itemToDelete: null,
            showDialogTextarea: false,
            showDialogSubmenuTextarea: false,
            showDialogIcon: false,
            menu: {},
            originalMenu: {},
            submenus: [],
            departments: [],
            operators: [],
            showEmoji: [],
            evasivas: [
                {
                    name: 'Me',
                },
                {
                    name: 'Não',
                },
                {
                    name: 'Preciso',
                },
            ],
            tags: [
                {
                    name: '%NOME_CLIENTE%',
                    description: 'Identifica nome do contato',
                },
                {
                    name: '%APELIDO_CLIENTE%',
                    description: 'Identifica apelido do contato',
                },
                {
                    name: '%DTN%',
                    description: 'Identifica mensagem de saudação de acordo com o horário de envio',
                },
                {
                    name: '#',
                    description: 'Voltar ao Menu Anterior',
                },
                {
                    name: '*',
                    description: 'Finalizar Atendimento',
                },
            ],
            i18n: {
                search: 'Pesquisar...',
                categories: {
                    Activity: "Atividades",
                    Flags: "Bandeiras",
                    Foods: "Comida",
                    Frequently: "Frequentes",
                    Objects: "Objetos",
                    Nature: "Natureza",
                    Peoples: "Pessoas",
                    Symbols: "Símbolos",
                    Places: "Locais"
                }
            }
        }
    }
}
</script>

<style>
    .cardpresentationcheckbox input{
        margin-right: 10px;
    }
    .cardpresentationcheckbox label{
        color: #555;
    }
    .emoji-picker #Categories {
        order: 5;
    }
    .emoji-picker #InputSearch {
        order: 4;
    }
    .emoji-picker .border{
        border: none !important;
    }
    .emoji-picker .emoji{
        display: flex !important;
        justify-content: center !important;
    }
    .emoji-picker .container-emoji{
        overflow: auto !important;
    }
    #modal-carimbo .close, #modal-evasivas .close, #modal-tags .close{
        border: none;
        background-color: transparent;
        font-size: 2em;
        color: #555;
    }
    #modal-carimbo .modal-title, #modal-evasivas .modal-title, #modal-tags .modal-title{
        color: #555 !important;
    }
    #modal-carimbo .modal-footer, #modal-evasivas .modal-footer, #modal-tags .modal-footer{
        display: none;
    }
    #modal-carimbo .col-form-label, #modal-evasivas .col-form-label, #modal-tags .col-form-label{
        color: #777;
        font-weight: 500;
    }
    .modal-content{
        border: none
    }
    .addimage .custom-file-input{
        display: none;
    }
    .addimage .custom-file-label{
        display: block;
        cursor: pointer;
    }
    .tooltip-orderChange>.tooltip-inner {
        color: #fff;
    }
    .formFile .custom-file-input{
        display: none !important;
    }
    .formFile .custom-file-label{
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
</style>

<style scoped>
    .page-managemenu .card-body{
        padding: 0;
    }
    .managemenurow{
        display: flex;
        justify-content: space-between;
    }
    .card{
        border: none;
    }
    .cardheader{
        padding: 30px;
        display: flex;
        align-items: center;
        border-bottom: 0.5px solid #eee;
    }
    .cardnumber{
        margin-right: 15px;
        background-color: hsl(94deg 44% 65%);
        color: #fff;
        display:inline-block;
        font-size:25px;
        font-weight: 500;
    }
    .cardnumber:before,
    .cardnumber:after {
        content:'\200B';
        display:inline-block;
        line-height:0px;

        padding-top:50%;
        padding-bottom:50%;
    }
    .cardnumber:before {
        padding-left:12px;
    }
    .cardnumber:after {
        padding-right:12px;
    }
    .cardtitle{
        display: inline;
        margin-top: 6px;
        color: #333;
    }
    .cardsubheader{    
        font-size: 0.9em;
        color: hsl(252deg 3% 68%);
        border-bottom: 0.5px solid #eee;
    }
    .cardpresentation{
        height: 100%;
    }
    .cardpresentationimg{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        color: #fff;
        background-color: hsl(249deg 9% 28%);
        padding: 79px;
        cursor: pointer;
        width: 100%;
    }
    .custom-file-label:hover .cardpresentationimg{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .cardpresentationicon{
        font-size: 4em;
    }
    .cardpresentationtext{
        font-size: 1.2em;
    }
    .cardpresentationcontent{
        padding: 20px 30px;
    }
    .cardmainmenucontent{
        padding: 30px;
    }
    .cardpresentationcontenttop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .managemenubtngreen:not(:last-child) {
        margin-right: 10px;
    }
    .managemenubtngreen{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        padding: 5px 20px;
    }
    .managemenubtnpink{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        padding: 5px 20px;
    }
    .managemenubtnred{
        background-color: hsl(360deg 83% 69%);
        border-color: hsl(360deg 83% 69%);
        padding: 5px 20px;
    }
    .managemenubtnwhite{
        background-color: #fff;
        color: #777;
        border-color: #eee;
    }
    .managemenubtnwhite:hover{
        background-color: #777;
        color: #fff 
    }
    select, input, textarea{
        border-color: #eee;
        color: #555;
    }
    .cardpresentationcontentbottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .cardmainmenuformline{
        display: flex;
        margin-bottom: 18px;
    }
    .cardmainmenuitem{
        display: flex;
        align-items: center;
        text-align: center;
    }
    .cardmainmenuitem:not(:last-child) {
        margin-right: .5em;
    }
    .cardmainmenunumber{
        width: 60px;
    }
    .cardmainmenuicon{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        width: 4em;
    }
    .cardmainmenuicon:hover{
        background-color: hsl(142deg 30% 50%);
        border-color: hsl(142deg 30% 50%);
    }
    .cardmainmenuname{
        margin: 0 !important;
        text-align: left;
    }
    .input-group .input-group-append{
        height: 100%;
    }
    .cardmainmenueyebtn{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
    }
    .cardmainmenueditbtn{
        background-color: hsl(93deg 44% 65%);
        border-color: hsl(93deg 44% 65%);
    }
    .cardmainmenuremovebtn{
        background-color: hsl(360deg 83% 69%);
        border-color: hsl(360deg 83% 69%);
    }
    .cardmainmenubtns{
        display: flex;
        justify-content: flex-end;
    }
    .cardbtns{
        margin-top: 25px;
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .form-control, .custom-select{
        padding: 0.375rem 0.75rem;
    }
    .modalinputrow{
        margin-bottom: 10px;
    }
    .modal select{
        width: 100%;
    }
    .evasivas{
        background-color: hsl(0deg 0% 93%);
        color: hsl(0deg 0% 60%);
        width: fit-content;
        padding: 2px 10px;
        border-radius: 5px;
        display: inline;
    }
    .evasivas:not(:last-child) {
        margin-right: 10px;
    }
    .toggle-emoji{
        position: absolute;
        bottom: 0;
        right: 8px;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .emoji-picker{
        position: absolute;
        bottom: 40px;
        right: 0;
        width: 250px;
        z-index: 1;
    }
    .textareaemoji-container, .iconemojipicker-container{
        position: relative;
    }
    .iconemojipicker{
        bottom: 0;
    }
    .modal-submenusDiv{
        border: 1px solid #eee;
        padding: 1em;
    }
    .modal-submenusDiv .cardmainmenubtns{
        justify-content: flex-start;
    }
</style>