<template>
    <div>
        <div class="bg-white rounded p-4 text-center shadow-sm" v-if="loadingPreparations">
            <b-spinner />
        </div>
        <div class="vstack gap-4" v-else>
            <div class="vstack gap-4 p-0">
                <div class="hstack justify-content-between bg-white rounded p-4 shadow-sm">
                    <div class="hstack gap-3 align-items-center">
                        <b-iconstack font-scale="4">
                            <b-icon stacked icon="circle-fill" variant="green"/>
                            <b-icon stacked icon="layout-text-window" scale=".5" variant="white"/>
                        </b-iconstack>
                        <div class="vstack justify-content-center">
                            <h5 class="m-0 lh-1">Lista de Templates</h5>
                            <div class="text-secondary small-text">{{ templatesTotal }} {{"Template" | pluralize(templatesTotal) }} {{ "Cadastrado" | pluralize(templatesTotal) }}</div>
                        </div>
                    </div>
                    <div>
                        <b-button class="py-1 px-4 text-white" pill variant="green" @click="createTemplate">Criar Novo Template</b-button>
                    </div>
                </div>
                <div class="row m-0 gap-4 justify-content-start" v-if="showTemplates">
                    <div :class="`col-4 bg-white rounded p-0 rounded shadow-sm`" v-for="(template, index) in templates" :key="index">
                        <div class="bg-dark text-white position-relative rounded-top">
                            <h3 class="text-center text-truncate py-6-5 m-0">
                                {{ template.name }}
                            </h3>
                            <div class="position-absolute top-0 end-0 m-4 d-flex gap-2">
                                <div v-b-tooltip.bottom.hover="{ customClass: 'top-0 mt-1', boundary: 'document' }" title="Editar">
                                    <b-iconstack class="darkenTextOnHover" font-scale="3" @click="pickMenu(template)" role="button">
                                        <b-icon stacked icon="circle-fill" variant="green"/>
                                        <b-icon stacked icon="pencil" scale=".5"/>
                                    </b-iconstack>
                                </div>
                                <div v-b-tooltip.bottom.hover="{ customClass: 'top-0 mt-1', boundary: 'document' }" title="Remover">
                                    <b-iconstack class="darkenTextOnHover" font-scale="3" @click="deleteTemplate(template)" role="button">
                                        <b-icon stacked icon="circle-fill" variant="red"/>
                                        <b-icon stacked icon="trash" scale=".5"/>
                                    </b-iconstack>
                                </div>
                            </div>
                        </div>
                        <div v-if="channels?.length">
                            <div class="px-4 py-3 border-bottom">
                                <h5>Canais Associados</h5>
                            </div>
                            <div class="p-4">
                                <span class="box-clamp-1" v-if="associatedChannels(template._id).length">
                                    <span class="text-secondary h6" v-for="(channel, i) in associatedChannels(template._id)" :key="channel._id">
                                        {{ channel.channelNumber | formatNumber }}<span v-if="i+1 < associatedChannels(template._id).length">,</span>
                                    </span>
                                </span>
                                <span class="text-secondary h6" v-else>
                                    ESTE TEMPLATE NÃO ESTÁ ASSOCIADO A UM CANAL!
                                </span>
                            </div>
                        </div>
                        <div v-if="contactTags?.length">
                            <div class="px-4 py-3 border-bottom border-top">
                                <h5>Etiquetas Associadas</h5>
                            </div>
                            <div class="p-4">
                                <span class="box-clamp-1" v-if="template.tagIds?.length">
                                    <span class="text-secondary h6" v-for="(tagId, i) in template.tagIds" :key="tagId">
                                        {{ getTagName(tagId) }}<span v-if="i + 1 < template.tagIds.length">,</span>
                                    </span>
                                </span>
                                <span class="text-secondary h6" v-else>
                                    N/A
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center p-4 bg-white rounded shadow-sm" v-if="templates && templatesTotal > perPage">
                    <b-pagination class="m-0"
                        :total-rows="templatesTotal"
                        v-model="currentPage"
                        :per-page="perPage"
                        @change="pageChange"
                    />
                    <span class="pagdescription">
                        <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{(currentPage * perPage > templatesTotal) ? templatesTotal : currentPage * perPage}} de {{ templatesTotal }} Templates Cadastrados</div>
                    </span>
                </div>
            </div>
            <b-row class="m-0 gap-4" v-if="menu">
                <b-col class="p-0 bg-white rounded shadow-sm">
                    <div>
                        <div class="cardheader">
                            <span class="cardnumber rounded-circle bg-green">01</span>
                            <h5 class="cardtitle">Nome do Template</h5>   
                        </div>
                        <div class="vstack gap-3 p-4">
                            <label for="menuName">
                                <div class="bg-green rounded text-white px-4 py-2">
                                    Nome do Template
                                </div>
                            </label>
                            <b-form-input id="menuName" v-model="menu.name"></b-form-input>
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0 bg-white rounded shadow-sm" v-if="channels?.length">
                    <div class="cardheader">
                        <span class="cardnumber rounded-circle bg-green" style="font-size: 22px">02</span>
                        <h5 class="cardtitle">Números dos Canais Associados</h5>   
                    </div>
                    <div class="vstack gap-3 p-4">
                        <!-- <v-select class="searchSelect" :options="secondaryChannels" :filter="(options) => { return options.filter(el => el && (!el.menuTemplateId || el.menuTemplateId == menu._id)) }" :loading="loadingCRMOrganizations" :reduce="name => name?._id" label="name" @input="rdOrgChanged" @search="rdSearchOrganizations">
                            <div slot="no-options">Nenhum canal disponível!</div>
                        </v-select> -->
                        <div class="border rounded w-100 text-secondary py-2 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseChannelSelect v-if="channels?.filter(el => el && (!el.channelConfig.menuTemplateId || el?.channelConfig.menuTemplateId === menu._id)).length">
                            <span class="box-clamp-1" v-if="channels.length">
                                <span v-for="(channel,i) in templateChannels" :key="channel._id">
                                    <span>
                                        {{ channel.channelNumber | formatNumber }}<span v-if="i+1 < templateChannels.length">,</span> 
                                    </span>
                                </span>
                            </span>
                            <span>
                                <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"/>
                            </span>
                        </div>
                        <div class="border rounded w-100 text-secondary py-2 px-3" v-else>
                            Nenhum canal disponível
                        </div>
                        <b-collapse id="collapseChannelSelect" ref="collapseChannelSelect" class="mt-2 position-absolute border rounded" style="z-index: 1">
                            <b-form-checkbox-group
                                class="bg-white"
                                style="z-index: 1"
                                v-model="templateChannels"
                                @change="templateChannelSelected"
                            >
                                <div v-for="item in channels?.filter(el => el && (!el.channelConfig.menuTemplateId || el?.channelConfig.menuTemplateId === menu._id))" :key="item.id">
                                    <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item"> 
                                        <div class="ms-1 me-5" role="button">{{ item.channelNumber | formatNumber }}</div>
                                    </b-form-checkbox>
                                </div>
                            </b-form-checkbox-group>
                            <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseChannelSelect>
                            </div>
                        </b-collapse>
                    </div>
                </b-col>
                <b-col class="p-0 bg-white rounded shadow-sm" v-if="contactTags?.length">
                    <div class="cardheader">
                        <span class="cardnumber rounded-circle bg-green" style="font-size: 22px">{{ channels?.length ? "03" : "02" }}</span>
                        <h5 class="cardtitle">Etiquetas Associadas</h5>   
                    </div>
                    <div class="p-4">
                        <v-select v-model="menu.tagIds" :reduce="tag => tag?._id" :options="contactTags" label="name" multiple>
                            <template slot="option" slot-scope="option">
                                <!-- <span :class="`flag-icon flag-icon-${option.value.toLowerCase()} flag-icon-squared me-2`"></span> -->
                                <span>{{ option.name }}</span>
                            </template>
                            <!-- <template #selected-option="value">
                                {{ getTagName(value) }}
                            </template> -->
                        </v-select>
                    </div>
                </b-col>
            </b-row>
            <b-row class="m-0" v-if="menu">
                <b-button class="py-2 px-4 text-white" variant="green" :disabled="saving" @click="saveMenu()">
                    Salvar dados do Template
                </b-button>
            </b-row>
            <b-row class="m-0" v-if="menu">
                <b-col class="p-0 bg-white rounded shadow-sm">
                    <div class="cardheader">
                        <span class="cardnumber rounded-circle bg-green" style="font-size: 22px">03</span>
                        <h5 class="cardtitle">Configuração do Template</h5>   
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import api from '../services/apiService.js'

export default {
    props: [
        'user',
        'setMenu'
    ],
    data() {
        return {
            menu: null,
            templateChannels: [],
            currentPage: 1,
            perPage: 9,
            templates: [],
            templatesTotal: 0,
            loadingPreparations: false,
            showTemplates: false,
            saving: false,
            channels: [],
            contactTags: [],
        }
    },
    mounted: async function () {
        await this.verifyTemplates()
    },
    methods: {
        async getMenuTemplates(page = 1) {
            const respTemplates = await api.getMenuTemplatesPagination(this.user.channelId || this.user.roleId, page)  
            console.log({respTemplates})
            if(respTemplates.statusCode === 200) {
                this.templates = respTemplates?.templates
                this.templatesTotal = respTemplates?.total

                if (respTemplates?.total > 1 && !this.showTemplates) {
                    this.showTemplates = true
                    this.$emit('showTemplates', true)
                }
            } else {
                this.templates = []
                this.templatesTotal = 0
            }
        },
        async verifyTemplates() {
            this.loadingPreparations = true

            const hasSecondaryChannels = await this.channelHasSecondaryChannels()

            if(hasSecondaryChannels) {
                const channels = await this.getChannels()
                this.channels = this.channels.filter(el => !el?.channelConfig?.operationalChannelEnabled)
                if(channels?.length) {
                    this.showTemplates = true
                    this.$emit('showTemplates', true)
                    // console.log({channels})
                }
            }

            await this.getMenuTemplates()
            await this.getContactTags()

            this.loadingPreparations = false
        },
        async channelHasSecondaryChannels() {
            const resp = await api.getChannelHasSecondaryChannels(this.user.channelId || this.user.roleId)
            // console.log("resp",resp)
            if(resp.statusCode == 200) {
                return resp.hasSecondaryChannels
            }

            return false
        },
        async getChannels() {
            try {
                const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
                if (resp.statusCode !== 200) {
                    this.channels = []
                    return this.channels
                }
                
                this.channels = resp.channels
                await Promise.allSettled(this.channels.map(async (el) => {
                    const config = await api.getChannelConfig(el._id)
                    this.$set(el, 'channelConfig', config.channelConfig)
                }))
                
                if (this.channels.length) {
                    const channelResp = await api.getChannel(this.user.channelId || this.user.roleId)
                    if (channelResp.statusCode === 200) {
                        const channel = channelResp.channel?.channel
                        if (channel) {
                            const config = await api.getChannelConfig(channel._id)
                            this.$set(channel, 'channelConfig', config.channelConfig)
                            this.channels.unshift(channel)
                        }
                    }
                }
            } catch (error) {
                console.error(error)
                this.channels = []
            }
            
            return this.channels
        },
        async getContactTags() {
            const resp = await api.getAllTags(this.user.channelId || this.user.roleId)

            if (resp.statusCode === 200) {
                this.contactTags = resp.tags
            }
        },
        getTagName(tagId) {
          if (this.contactTags?.length) {
            return this.contactTags.find(tag => tag._id === tagId)?.name
          }  
        },
        pickMenu(menu) {
            this.menu = menu
            this.templateChannels = this.associatedChannels(menu._id)
            this.setMenu(menu)
        },
        pageChange(page) {
            if(page != this.currentPage) {
                this.currentPage = page
                this.getMenuTemplates(page)
            }
        },
        associatedChannels(templateId) {
            if (!this.channels?.length) return []

            const channels = this.channels.filter(el => el?.channelConfig?.menuTemplateId == templateId)

            if (!channels.length) {
                const mainChannel = this.channels.find(el => !el.mainChannelId)
                if (mainChannel && !mainChannel.channelConfig.menuTemplateId) {
                    return [mainChannel]
                }
            }

            return channels
        },
        async templateChannelSelected(channels) {
            channels.map(async (channel) => {
                const index = this.channels.findIndex(c => c._id === channel._id)
                if (index >= 0 && this.channels[index]?.channelConfig?.menuTemplateId != this.menu._id) {
                    const channelConfig = {
                        ...this.channels[index].channelConfig,
                        menuTemplateId: this.menu._id
                    }

                    this.$set(this.channels[index], 'channelConfig', channelConfig)
                }
            })

            if (this.channles?.length) {
                const difference = this.channels.filter(
                    c => !channels.some(
                        channel => channel._id === c._id
                    )
                )
    
                difference.forEach(channel => {
                    if(channel.channelConfig.menuTemplateId == this.menu._id)
                        channel.channelConfig.menuTemplateId = null
                })
            }

        },
        async createTemplate() {
            const menu = {
                name: 'Novo Template',
                channelId: this.user.channelId || this.user.roleId
            }
            const resp = await api.createMenu(menu)
            if(resp.statusCode === 201) {
                this.$emit("msg", { text: "Template criado com sucesso!", success: true })
                this.getMenuTemplates()
            } else {
                this.$emit("msg", { text: "Ocorreu um erro!", success: false })
            }
        },
        async deleteTemplate(template) {
            const resp = await api.deleteMenu(template._id)
            if(resp.statusCode == 200) {
                this.$emit("msg", { text: "Template removido com sucesso!", success: true })
                this.getMenuTemplates()
            } else {
                this.$emit("msg", { text: "Ocorreu um erro!", success: false })
            }
        },
        async saveMenu() {
            if (!this.menu) {
                this.$emit("msg", { text: "Selecione um template!", success: false })
            }

            this.saving = true

            if (!this.menu.tagIds?.length) {
                this.menu.tagIds = ["null"]
            }

            const resp = await api.updateMenu({ menus: [ this.menu ] })

            if (this.menu.tagIds?.length && this.menu.tagIds.at(0) === "null") {
                this.menu.tagIds = []
            }

            for (const channel of this.channels) {
                if (channel.channelConfig.menuTemplateId === this.menu._id) {
                    await api.updateChannelConfig(channel.channelConfig)
                }
            }
            
            if(resp.statusCode === 200) {
                this.$emit("msg", { text: "Template atualizado com sucesso!", success: true })
                this.setMenu(this.menu)
            } else {
                this.$emit("msg", { text: "Ocorreu um erro!", success: false })
            }

            this.saving = false
        }
    }
}
</script>

<style>
    .card{
        border: none;
    }
    .cardheader{
        padding: 24px;
        display: flex;
        align-items: center;
        border-bottom: 0.5px solid #eee;
    }
    .cardnumber{
        margin-right: 15px;
        background-color: hsl(94deg 44% 65%);
        color: #fff;
        display:inline-block;
        font-size:25px;
        font-weight: 500;
    }
    .cardnumber:before,
    .cardnumber:after {
        content:'\200B';
        display:inline-block;
        line-height:0px;
        padding-top:50%;
        padding-bottom:50%;
    }
    .cardnumber:before {
        padding-left:12px;
    }
    .cardnumber:after {
        padding-right:12px;
    }
    .cardtitle {
        display: inline;
        margin-top: 6px;
        color: #333;
    }
    .cardsubheader {    
        font-size: 0.9em;
        color: hsl(252deg 3% 68%);
        border-bottom: 0.5px solid #eee;
    }
</style>